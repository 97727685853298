import React from 'react';

const Home = () => {
  return (
    <div >
      {/* <img className='background'
      src='./assets/lake-oakland.JPG'
      alt=''
      /> */}
      <div >
          <h1>Welcome to My Website</h1>
      </div>
    </div>
  )
}

export default Home;