import React from 'react'

const Trips = () => {
  return (
    <div>
        <h1>What is a trip?</h1>
    </div>
  );
};

export default Trips;